import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Импортируем регистрацию сервис-воркера

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Регистрируем сервис-воркер
serviceWorkerRegistration.register();

// Если хотите начать измерение производительности в вашем приложении
reportWebVitals();